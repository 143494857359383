import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sf-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    standalone: false
})
export class UserCardComponent implements OnInit {
  @Input() user;

  constructor() { }

  ngOnInit(): void {
  }

}
