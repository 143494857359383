import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReferenceRenderer, CSLReference, CitationItem } from '@sciflow/cite';

@Injectable({
  providedIn: 'root'
})
export class RenderingService {

  styleUrl = '/export/csl';
  localUrl = '/export/csl/locales-';
  defaults: { styleXML: string; localeXML: string; };

  constructor(private snackBar: MatSnackBar) {
    this.loadDefaults();
  }

  /**
   * Renders one or more references as a citation.
   */
  render(citationItems: CitationItem[], references: CSLReference[]): string {
    if (!this.defaults?.styleXML) { throw new Error('No styles loaded'); }
    const renderer = new ReferenceRenderer(this.defaults?.styleXML, this.defaults.localeXML, references, { format: 'text' });
    return renderer.renderCitationOutOfContext(citationItems);
  }

  public getBibliography(references: CSLReference[]): string[] {
    if (!this.defaults?.styleXML) { throw new Error('No styles loaded'); }
    const renderer = new ReferenceRenderer(this.defaults?.styleXML, this.defaults.localeXML, references, { format: 'text' });
    const bibliography = renderer.getBibliographyFromAll();
    return bibliography.references;
  }

  /**
   * Loads the default citation style and locale.
   */
  private async loadDefaults(): Promise<void> {
    try {
      const styleXML = await this.getStyleXML();
      const localeXML = await this.getLocaleXML();

      this.defaults = { styleXML, localeXML };
    } catch (e) {
      this.snackBar.open(e.message, undefined, { duration: 10000 });
    }
  }

  private async getStyleXML(id = 'apa'): Promise<string> {
    const res = await fetch(`${this.styleUrl}/${id}.xml`);

    if (res.status === 504) {
      throw new Error('The backend was not available to fetch the citation styles');
    }

    if (res.status === 200) {
      return res.text();
    }

    throw new Error('Could not load citation style ' + id);
  }

  private async getLocaleXML(locale = 'en-US') {
    const res = await fetch(`${this.localUrl}${locale}.xml`);

    if (res.status === 200) {
      return res.text();
    }

    throw new Error('Could not load locale');
  }
}
