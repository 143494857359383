<div
  class="flex col-12"
  *ngIf="(!formErrors() || !schemaErrors()) && formReady() && (schema$ | async) as schema; else showErrors"
  style="height: 100%;"
>
  <form [formGroup]="parentForm" class="col-12">
    <div [ngSwitch]="settingsView" style="height: 100%;">
      <ng-container *ngIf="schema">
        <sfo-quicksettings-view
          *ngSwitchCase="'quickSettings'"
          [schema]="schema"
          [aFormControl]="parentForm"
        ></sfo-quicksettings-view>

        <sfo-simple-settings-view
          *ngSwitchCase="'settings'"
          [schema]="schema"
          [aFormControl]="parentForm"
        ></sfo-simple-settings-view>

        <settings-tab-view
          *ngSwitchDefault
          [schema]="schema"
          [aFormControl]="parentForm"
        ></settings-tab-view>
      </ng-container>
    </div>
  </form>
</div>

<ng-template #showErrors>
  <div class="flex flex-column col-12 justify-center items-center" style="height: 100%;">
    @if (formErrors()) {
      <sfo-admonition type="danger" title="Form Error" expanded hideToggle>
        <span>{{formErrors()}}</span>
      </sfo-admonition>
    }

    @if (schemaErrors()) {
      <sfo-admonition type="danger" title="Schema Error" expanded hideToggle>
        <span>{{schemaErrors()}}</span>
      </sfo-admonition>
    }
  </div>
</ng-template>
