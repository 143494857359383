import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';

@Component({
    selector: 'sf-figure-preview-modal',
    templateUrl: './figure-preview-modal.component.html',
    styleUrls: ['./figure-preview-modal.component.scss'],
    standalone: false
})
export class FigurePreviewModalComponent {

  constructor(public dialogRef: MatDialogRef<FigurePreviewModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private apollo: Apollo, private store: Store) {
  }

  close() {
    this.dialogRef.close();

  }

}
