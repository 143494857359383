import { Component, signal, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JSONEditor } from '@json-editor/json-editor';
import { Store } from '@ngrx/store';
import { Apollo, gql } from 'apollo-angular';
import { selectDocumentStateIsDirty, selectInstances, updateDocumentState } from 'editor';
import * as yaml from 'js-yaml';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import * as ExportActions from '../store/export.actions';
import {
  selectExportDcaMaximize,
  selectExportMaximize,
  selectMetadata,
  selectTemplate,
} from '../store/export.reducer';

@Component({
    selector: 'lib-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss'],
    standalone: false
})
export class ExportComponent {
  templateSlug$ = this.activatedRoute.params.pipe(
    map((p: any) => p.template),
    startWith(this.activatedRoute.snapshot.params?.value?.template),
  );
  activeEditor$ = this.store.select(selectInstances).pipe(
    filter((instances) => instances.length > 0),
    map((instances) => instances[0]),
  );
  activeTemplate$ = this.templateSlug$.pipe(
    startWith(this.activatedRoute.snapshot.params.template),
    debounceTime(100),
    switchMap((template: string) => this.loadTemplate(template)),
    shareReplay(1),
  );

  exportSettingsMaximize$ = this.store.select(selectExportMaximize);
  exportDcaMaximize$ = this.store.select(selectExportDcaMaximize);

  templates$ = this.loadTemplates();
  metaDataEditorDirty$ = new BehaviorSubject(false);
  isDocumentStateDirty$ = this.store.select(selectDocumentStateIsDirty);

  @ViewChild('metaData') metaDataEl;
  metaDataEditor: JSONEditor;

  loading$ = new BehaviorSubject(false);

  data$ = combineLatest([this.activeEditor$, this.activeTemplate$, this.templates$]).pipe(
    map(([editor, template, templates]) => ({ editor, template, templates })),
  );

  hideMultipleSelectionIndicator = signal(false);
  uiVisibilityRoles: { id: string; label: string; }[] = [
    { id: 'author', label: 'Standard' },
    { id: 'editor', label: 'Advanced' },
  ];
  selectedUiRoles: string[] = ['author'];

  constructor(
    private store: Store,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  async exportWithTemplate(template: string, key?: string, format = 'pagedjs'): Promise<void> {
    window.open(`/export/${format}/${template}/${key}`, '_blank');
  }

  async exportSnapshot(key: string): Promise<void> {
    window.open(`/export/snapshot/${key}.zip`, '_blank');
  }

  async openLens(template: string, key?: string, format = 'xml') {
    const url = `/export/lens/?url=/export/${format}/${template}/${key}`;
    window.open(url, '_blank');
  }

  compareTemplate(template, slug: string) {
    return template === slug;
  }

  canEdit(key?: string) {
    return key?.includes('.json');
  }

  /**
   * Select a template (and change the url)
   * @param template the template slug
   */
  async navigateToTemplate(template: string) {
    this.activatedRoute.snapshot.params.template
      ? this.router.navigate(['../' + template], { relativeTo: this.activatedRoute })
      : this.router.navigate([template], { relativeTo: this.activatedRoute });
  }

  public resetMetaData() {
    this.store.dispatch(ExportActions.updateMetadata({ metaData: null }));
    this.store.dispatch(updateDocumentState({ dirty: true }));
  }

  async loadTemplates() {
    const templateResult: any = await this.apollo
      .query({
        query: gql`query GetTemplates {
        templates {
          slug
          type
          title
          description
          hidden
        }
      }`,
        variables: {},
      })
      .toPromise();

    return templateResult?.data?.templates;
  }

  async loadTemplate(template?: string) {
    if (!template) {
      return null;
    }

    this.loading$.next(true);
    this.snackBar.open('Loading template ...');

    const documentResult: any = await this.apollo
      .query({
        query: gql`query GetTemplate($template: String!, $projectId: String) {
        template(templateId: $template, projectId: $projectId) {
          slug
          title
          description
          readme
          type
          metaData
          configurations
          runners
        }
      }`,
        variables: {
          template,
        },
      })
      .toPromise();

    this.loading$.next(false);
    this.snackBar.open('Loaded ' + documentResult.data?.template?.title, undefined, {
      duration: 2000,
    });
    console.log('loaded template', documentResult.data.template);

    this.store.dispatch(ExportActions.updateTemplate({ template: documentResult.data.template }));

    return documentResult?.data?.template;
  }

  toggleMetaData() {
    this.store.dispatch(ExportActions.toggleExportSettings());
    this.router.navigate(['setting'], { relativeTo: this.activatedRoute });
  }

  closeMetaDataEditor() {
    this.store.dispatch(ExportActions.minimizeExportSettings());
  }

  expandSettings() {
    this.store.dispatch(ExportActions.maximizeExportSettings());
    this.router.navigate(['setting'], { relativeTo: this.activatedRoute });
  }

  toggleMultipleSelectionIndicator() {
    this.hideMultipleSelectionIndicator.update((value) => !value);
  }

  onToggleChange(values: string[]) {
    this.selectedUiRoles = values;
  }

  async downloadTemplate() {
    const template = await firstValueFrom(this.store.select(selectTemplate).pipe(take(1)));
    const metaData = await firstValueFrom(this.store.select(selectMetadata).pipe(take(1)));

    try {
      const newTemplate = this.patchTemplateWithMetadata(metaData, template);
      

      const yaml = this.convertJsonToYaml({
        ...newTemplate,
        configurations: newTemplate.configurations.map(conf => {
          if (conf.kind === 'Configuration') {
            conf = {
              ...conf,
              spec: {
                ...(conf.spec || {}),
                assets: (conf.spec?.assets || []).map((asset) => {
                  if (asset?.content) {
                    // Create a clone of the asset object to safely delete properties
                    asset = {...asset};
                    delete asset.content;
                  }
                  return asset;
                }),
              },
            };
          }
          return conf;
        })
      });

      await navigator.clipboard.writeText(yaml);
      this.snackBar.open(`Template copied to clipboard.`, 'Close', { duration: 5000 });
    } catch (err) {
      this.snackBar.open(`There was an error generating the template.`, 'Close', { duration: 5000 });
      console.error('Failed to copy text: ', err);
    }
  }

  private patchTemplateWithMetadata(metadata: any, template: any) {
    if (!metadata) {
      return template;
    }

    const clonedMetadata = structuredClone(metadata);
    const clonedTemplate = structuredClone(template);

    Object.keys(clonedMetadata).forEach((metadataKey) => {
      const metaDataProperty = clonedTemplate.metaData.properties[metadataKey];

      // Find the matching configuration in template.configurations
      const matchingConfig = clonedTemplate.configurations.find((config: any) => {
        return (
          config.kind === metaDataProperty.component.kind &&
          metaDataProperty.component.runners?.every((runner: string) =>
            config.runners.includes(runner),
          )
        );
      });

      if (matchingConfig) {
        const spec = matchingConfig.spec;
        const metadataValues = clonedMetadata[metadataKey];

        const mergeSpec = (spec: any, metadataValues: any, defaultProperties: any) => {
          Object.keys(metadataValues).forEach((key) => {
            if (defaultProperties && defaultProperties[key]) {
              if (metadataValues[key] === defaultProperties[key].default) {
                return;
              }

              if (typeof metadataValues[key] === 'object' && !Array.isArray(metadataValues[key])) {
                if (!spec[key]) {
                  return;
                }
                mergeSpec(spec[key], metadataValues[key], defaultProperties[key].properties);
              } else {
                spec[key] = metadataValues[key];
              }
            }
          });
        };

        mergeSpec(spec, metadataValues, metaDataProperty.properties);
      }
    });

    // Return the patched template
    return clonedTemplate;
  }

  convertJsonToYaml(jsonObject: any): string {
    let yamlDocuments: string[] = [];

    // Convert each configuration in the JSON to a YAML document
    jsonObject.configurations.forEach((config: any) => {
      let yamlDoc = yaml.dump(config, { sortKeys: false });
      yamlDocuments.push(yamlDoc);
    });

    // Join the YAML documents with "---" as the separator
    return yamlDocuments.join('---\n');
  }
}
