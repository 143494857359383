<div class="user-card p2 col-12">
    <div class="flex items-center">
        <div class="pr1">
            <sf-user-avatar [user]="user"></sf-user-avatar>
        </div>
        <div>
            <h4 class="m0 pb1">
                <span *ngIf="user.name">
                    {{ user.name }}
                </span>
                <span *ngIf="user?.firstName || user?.lastName">
                    {{user.firstName ? user.firstName : ''}}
                    {{user.lastName ? user.lastName : ''}}
                </span>
                <span *ngIf="!user?.firstName && !user?.lastName">
                    {{user.email ? user.email : '⠀'}}
                </span>                
                <sup *ngIf="user.correspondingAuthor">*</sup>
                <span *ngIf="user.currentUser">(you)</span>
            </h4>
            <div class="flex">
                <!-- <div class="online-state"></div> -->
                <small *ngIf="user.userId">
                    <span *ngFor="let role of user.roles">
                        <span [ngSwitch]="role">
                            <!-- <span *ngSwitchCase="'author'"><mat-icon class="mat-16" matTooltip="Author">account_box</mat-icon></span> -->
                            <span *ngSwitchCase="'Editor'" class="pr1"><mat-icon class="mat-16 mat-icon-gray " matTooltip="Editor">check</mat-icon></span>
                            <span *ngSwitchCase="'CommentOnly'" class="pr1"><mat-icon class="mat-16 mat-icon-gray " matTooltip="Comment only">comment</mat-icon></span>
                        </span>
                    </span>
                </small>
                <small *ngIf="user.type === 'group'" matTooltip="This is a group or organization" class="pr1"><mat-icon class="mat-16 mat-icon-gray">corporate_fare</mat-icon></small>
                <!-- <small *ngIf="user.invitedAt" matTooltip="The author has been invited" class="pr1"><mat-icon class="mat-16 mat-icon-gray">email</mat-icon></small> -->
                <small *ngIf="!user.userId && !user.invitedAt" matTooltip="Author has has no SciFlow account" class="pr1"><mat-icon class="mat-16 mat-icon-gray">no_accounts</mat-icon></small>
                <small *ngIf="user.deceased" matTooltip="Author is deceased" class="pr1">†</small>
                <small *ngIf="user.userId && user.emailVerified" matTooltip="Email verified" class="pr1"><mat-icon class="mat-16 mat-icon-gray">verified_user</mat-icon></small>
                <small *ngIf="user.hideInPublication" matTooltip="Author is hidden from publications" class="pr1"><mat-icon class="mat-16 mat-icon-gray">visibility_off</mat-icon></small>
            </div>
        </div>
    </div>
</div>


