import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sfo-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.css'],
    standalone: false
})
export class InfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
