import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'sf-group-dialog',
    templateUrl: './group-dialog.component.html',
    styleUrls: ['./group-dialog.component.scss'],
    standalone: false
})
export class GroupDialogComponent {

  name = new UntypedFormControl();

  constructor(private dialogRef: MatDialogRef<GroupDialogComponent>, private snackbar: MatSnackBar) { }

  save() {
    if (this.name.pristine) { return this.dialogRef.close(); }
    this.dialogRef.close({ name: this.name.value });
    this.snackbar.open('Saved Group successfully', 'Close', { duration: 5000 });
  }

}
