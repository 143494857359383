import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any, searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return []; }

    searchText = searchText.toLowerCase();

    return items.filter( item => {
      return item.bib.toLowerCase().includes(searchText);
    });
  }

}
