import {Component, Inject, model} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SFNodeType} from '@sciflow/schema';
import {Node} from 'prosemirror-model';
import {BehaviorSubject} from 'rxjs';

interface NodeMetaData {
  node: Node;
  schema: {
    [key: string]: any;
  };
  sfNodeType: SFNodeType;
}

@Component({
  selector: 'sfo-node-meta-editor-dialog',
  templateUrl: './node-meta-editor-dialog.component.html',
  standalone: false,
})
export class NodeMetaEditorDialog {
  readonly nodeMetaData = model<object | null>(null);
  readonly nodeMetaDataSchema = model(this.data.schema);
  readonly sfNodeType = model<SFNodeType>(this.data.sfNodeType);

  form$ = new BehaviorSubject<object | null>(null);

  constructor(
    private dialogRef: MatDialogRef<NodeMetaEditorDialog>,
    @Inject(MAT_DIALOG_DATA) readonly data: NodeMetaData,
  ) {
    this.nodeMetaData.set(this.processInitialAttrs(this.data.node));
  }

  updateForm(values) {
    this.form$.next(values);
  }

  onCancel(): void {
    this.dialogRef.close({ event: 'cancelled' });
  }

  onSave(): void {
    let formValues = this.form$.getValue();

    if (formValues === null) {
      this.onCancel();
      return;
    }

    this.dialogRef.close({ event: 'submitted', data: formValues?.[this.sfNodeType()] });
  }

  private processInitialAttrs(node) {
    const result = {
      [this.sfNodeType()]: {
        alt: node?.attrs?.alt || '',
        decorative: node?.attrs?.decorative,
      },
    };

    return result;
  }
}
