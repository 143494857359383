import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT_PORTAL_DATA } from '../pm-editor.component';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { SFNodeType } from 'projects/schema/src/lib/types';

@Component({
    selector: 'sfo-structure-menu',
    templateUrl: './structure-menu.component.html',
    styleUrls: ['./structure-menu.component.scss'],
    animations: [
        trigger('fadeInAndOut', [
            state('in', style({ opacity: 1 })),
            transition('void => *', [
                style({
                    opacity: 0
                }),
                animate('2s ease-in')
            ]),
            transition('* => void', [
                animate('2s 2s ease-out', style({
                    opacity: 0
                }))
            ])
        ])
    ],
    standalone: false
})
export class StructureMenuComponent implements OnInit {

  placements = [
    { label: 'Cover', value: 'cover' },
    { label: 'Front', value: 'front' },
    { label: 'Body (default)', value: 'body' },
    { label: 'Back', value: 'back' }
  ];

  headingTypes = [
    { id: 'h1', type: 'chapter', icon: 'filter_1' },
    { id: 'h1', type: 'abstract', icon: 'filter_1' },
    { id: 'h1', type: 'bibliography', icon: 'filter_1' },
    { id: 'h1', type: 'appendix', icon: 'filter_1' },
    { id: 'h1', type: 'part', icon: 'filter_1' }
  ];

  headings = [
    { level: 1, icon: 'filter_1', type: 'chapter' },
    { level: 2, icon: 'filter_2', type: 'heading' },
    { level: 3, icon: 'filter_3', type: 'heading' },
    { level: 4, icon: 'filter_4', type: 'heading' },
    { level: 5, icon: 'filter_5', type: 'heading' }
  ];

  counterStyle = [
    { id: 'h1', numbering: 'decimal', text: '1, 2, ..' },
    { id: 'h1', numbering: 'upper-alpha', text: 'A, B, ..' },
    { id: 'h1', numbering: 'lower-alpha', text: 'a, b, ..' },
    { id: 'h1', numbering: 'lower-roman', text: 'i, ii, ..' },
    { id: 'h1', numbering: 'upper-roman', text: 'I, III, ..' },
    { id: 'h1', numbering: 'none', text: 'None' },
    { id: 'h1', numbering: undefined, text: 'Default' }
  ];

  constructor(@Inject(DOCUMENT_PORTAL_DATA) public data) {}

  setHeadingLevel(level = 1) {
    if (level === 0) {
      return this.setNodeType(SFNodeType.paragraph);
    }
    
    if (this.data.node?.type.name !== SFNodeType.heading) {
      this.setNodeType(SFNodeType.heading);
    }
    this.data.exec({ id: 'set-heading-level', payload: { level } });
  }

  setNodeType(type: string) {
    this.data.exec({ id: 'set-node-type', payload: { type } });
  }

  setType(type: string, level?: number) {
    this.data.exec({ id: 'set-part-type', payload: { type, level: level ?? 1 } })
  }

  setRole(role: string) {
    this.data.exec({ id: 'set-part-role', payload: { role } })
  }

  setNumbering(numbering?: string) {
    this.data.exec({ id: 'set-part-numbering', payload: { numbering } });
  }

  setPlacement(placement?: string) {
    this.data.exec({ id: 'set-part-placement', payload: { placement } });
  }

  addRole(): void {
    const role = prompt('Please enter role', '');
    if (role != null) {
      this.setRole(role);
    }
  }

  ngOnInit(): void {
  }

}
