import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByType',
    standalone: false
})
export class FilterByTypePipe implements PipeTransform {

    transform(contentTypes: any[], selectedTypes: any[]) {
        if (contentTypes) {
            return contentTypes.filter(item => selectedTypes.includes(item.type));
        } else {
            return [];
        }
    }

}
