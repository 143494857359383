import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ServerError } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { HttpLink } from 'apollo-angular/http';
import { onError } from "@apollo/client/link/error";
import { NetworkError } from "@apollo/client/errors";
import { MatSnackBar } from '@angular/material/snack-bar';

export function createApollo(httpLink: HttpLink, snackBar: MatSnackBar): ApolloClientOptions<any> {
  const uri = '/api/graphql';

  const uploadLink = createUploadLink({
    uri,
    headers: {
      'X-Apollo-Operation-Name': 'uploadFile'
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) {
      const error = networkError as ServerError;
      console.log(`[Network error]: ${networkError}`);
      if (error.statusCode === 504) {
        snackBar.open(`[${error.name}] The backend was not available (504)`);
      } else if (error.statusCode > 500) {
        snackBar.open(`[${error.name}] A network error occured`);
      } else {
        snackBar.open(`[${error.name}] A error occured: ${error.statusCode}`);
      }
    }
  });

  return {
    link: errorLink.concat(uploadLink),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, MatSnackBar],
    },
  ],
})
export class GraphQLModule { }
