import { createAction, props } from '@ngrx/store';
import { AuthorInUI, OptionState } from './author-management.reducer';

/**
 * Resets the author store
 */
export const reset = createAction('[Author Management] Reset');

/**
 * Sets the state to unsaved
 */
export const markUnsaved = createAction('[Author Management] Mark unsaved');

/**
 * Sets the state to unsaved
 */
export const markSaved = createAction('[Author Management] Mark saved');

/**
 * Sets the state to maximized
 */
export const maximized = createAction('[Author Management] DCA Maximized');

 /**
  * Sets the state to minimized
  */
export const minimized = createAction('[Author Management] DCA Minimized');

/**
 * Initialize authors.
 */
export const initializeAuthors = createAction('[Author Management] Initialize', props<{ authors: AuthorInUI[]; unrestricted?: boolean; }>());

/**
 * Add another author
 */
export const addAuthor = createAction('[Author Management] Add', props<{ author: AuthorInUI }>());

/**
 * Delete an author
 */
export const deleteAuthor = createAction('[Author Management] Delete', props<{ authorId: string }>());

/**
 * Should be triggered from outside to signal that an author was saved (with the updated author).
 */
export const authorSaved = createAction('[Author Management] Saved', props<{ authorId: string; author: AuthorInUI; }>());

/**
 * Should be triggered from outside to signal that an author was NOT saved (with errors)
 */
export const authorNotSaved = createAction('[Author Management] Not saved', props<{ authorId: string; message: string; errors: { field: string; message: string }[] }>());

/**
 * Mark an author as selected
 */
export const setSelectedAuthor = createAction('[Author Management] Select author', props<{ authorId: string | null; }>());

/**
 * Update an author or insert a new one.
 */
export const updateAuthor = createAction('[Author Management] Update', props<{ author: AuthorInUI, clearData?: boolean; updateProfile?: boolean; }>());

/**
 * Clear author data.
 */
export const clearAuthorData = createAction('[Author Management] Clear', props<{ authorId: string }>());


/**
 * Hides the author from publications
 */
export const toggleHideInPublication = createAction('[Author Management] Hide/Show in publication', props<{ authorId: string; hide: boolean; }>());

/**
 * Marks the author as a group of contributors.
 */
export const makeGroupContribution = createAction('[Author Management] Make group contribution', props<{ authorId: string; }>());

/**
 * Update the position of an author.
 */
export const updatePosition = createAction('[Author Management] Update position', props<{currentIndex: number; previousIndex: number}>());

export const createAuthor = (authorId, rank = Number.POSITIVE_INFINITY, userId = '') => ({
    id: '',
    type: 'author' as ('author' | 'group'),
    authorId,
    invitedAt: new Date(),
    userId,
    title: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    twitter: '',
    orcid: '',
    positions: [],
    groups: [],
    funders: [],
    equalContribution: true,
    correspondingAuthor: false,
    rank,
    deceased: false,
    editable: true,
    emailVerified: false,
    currentUser: false,
    allowedRoles: ['Author'],
    hideInPublication: false
  });

/**
 * Initialize Options.
 */
export const initializeUsers = createAction('[User] Initialize options', props<{ user: OptionState }>());
