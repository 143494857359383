import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'sfo-cite',
    templateUrl: './cite.component.html',
    styleUrls: ['./cite.component.css'],
    standalone: false
})
export class CiteComponent implements OnInit {

  _documentId: string;
  set documentId(value: string) {
    this._documentId = value;
  }

  hasReferences$ = new BehaviorSubject(null);
  selectedReferences: any[] = [];
  customFormat: null | 'suppress-author' | 'author-only' | 'combined';
  @ViewChild(MatButtonToggleGroup) customFormatOptions: MatButtonToggleGroup;

  searchInput = new UntypedFormControl();
  filteredReferences$ = new Subject();

  searching$ = merge(
    this.searchInput.valueChanges.pipe(map(_ => true)),
    this.filteredReferences$.pipe(map(_ => false)
    ));

  constructor(public dialogRef: MatDialogRef<CiteComponent>) { }

  ngOnInit(): void {
  }

  public displayFn(ref: any): string {
    if (!ref) { return ''; }
    return ref.name;
  }

  selected({ option }) {
    console.log('selected', option);
  }

  close() {
    this.dialogRef.close();
  }

  removeReference(change: any) {
    const i = this.selectedReferences.indexOf(change);
    if (i !== -1) {
      this.selectedReferences.splice(i, 1);
      this.referenceChange();
    }
  }

  // SF-57 TODO if the user has selected a reference twice, she cant set the page individually => two-way-data-binding
  public referenceChange(change?: any) {
    console.log('reference', change)
  }

  closeAndSubmit() {
    this.selectedReferences = this.selectedReferences.map(r => {

      if (this.customFormatOptions.value === 'combined') {
        r.options['suppress-author'] = 1;
        r.options['author-only'] = 1;
      } else if (this.customFormatOptions.value === 'suppress-author') {
        r.options['suppress-author'] = 1;
        delete r.options['author-only'];
      } else if (this.customFormatOptions.value === 'author-only') {
        delete r.options['suppress-author'];
        r.options['author-only'] = 1;
      } else {
        delete r.options['suppress-author'];
        delete r.options['author-only'];
      }

      return r;
    });

    this.dialogRef.close(this.selectedReferences);
  }

}
