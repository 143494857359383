import {Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EditorService} from '../../editor.service';
import {QuickActionsMenuComponent} from './quick-actions-menu/quick-actions-menu.component';

@Component({
    selector: 'sfo-quick-actions',
    template: '',
    standalone: false
})
export class QuickActionsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private overlayRef: OverlayRef | null = null;

  constructor(
    private editorService: EditorService,
    private cdr: ChangeDetectorRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
  ) {}

  ngOnInit() {
    this.editorService.currentView.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      if (!view) {
        this.hideMenu();
        return;
      }

      if (view?.state?.selection?.['node']?.type?.name === 'image') {
        const {from} = view.state.selection;
        const domNode = view.nodeDOM(from);

        if (domNode && domNode instanceof HTMLElement) {
          this.showOverlay(domNode);
        }
      } else {
        this.hideMenu();
      }

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.hideMenu();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private showOverlay(targetElement: HTMLElement) {
    this.hideMenu();

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(targetElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 8,
        },
      ])
      .withPush(false)
      .withFlexibleDimensions(false);

    const overlayConfig: OverlayConfig = {
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: false,
    };

    const quickActionsPortal = new ComponentPortal(QuickActionsMenuComponent);

    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(quickActionsPortal);
  }

  private hideMenu() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
