import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { DocumentOutlineService } from '../document-outline.service';
import { selectPart } from '../store/outline.reducer';

@Component({
    selector: 'lib-outline',
    templateUrl: './outline.component.html',
    styleUrls: ['./outline.component.scss'],
    standalone: false
})
export class OutlineComponent implements OnInit {

  contentTypes: string[] = ['heading', 'figure', 'table', 'Equation', 'code'];
  selectedTypes = ['heading'];

  @ViewChild('select') select: MatSelect;
  allSelected = false;

  headings$ = this.store.select(selectPart).pipe(map((elements) => elements?.filter(element =>
    (element.type !== 'footnote') && (element.type !== 'list_item') && (element.type !== 'citation') && (element.type !== 'bullet_list') )));

  constructor(private store: Store, private documentOutlineService: DocumentOutlineService) { }

  ngOnInit(): void {
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  headingClick(heading: any): void {
    this.documentOutlineService.focusElement(heading.id);
  }

  dragStart(event, heading): void {
    event.dataTransfer.setData('text/html', `<a data-type="xref" href="#${heading.id}">@</a>`);

    const clone = event.target.cloneNode(true);
    clone.className = event.target.className + ' js-cross-referencing-box';
    clone.style.width = event.target.offsetWidth + 'px';
  }

  dragEnd(event): void {}

}
