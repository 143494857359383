<h2 mat-dialog-title>Edit</h2>

<mat-dialog-content class="col-12">
  <sfo-dynamic-form
    [jsonSchema]="nodeMetaDataSchema()"
    [settingsView]="'settings'"
    [formValue]="nodeMetaData()"
    (formEvent)="updateForm($event)"
  ></sfo-dynamic-form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>
