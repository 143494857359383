import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Store } from '@ngrx/store';
import countries from '../countries.json';

@Component({
    selector: 'sf-funder-dialog',
    templateUrl: './funder-dialog.component.html',
    styleUrls: ['./funder-dialog.component.scss'],
    standalone: false
})
export class FunderDialogComponent implements OnInit {

  funder = new UntypedFormGroup({});
  countryList = countries;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private store: Store, private dialogRef: MatDialogRef<FunderDialogComponent>, private snackbar: MatSnackBar) { }

  save() {
    if (this.funder.pristine) { return this.dialogRef.close(); }
    this.dialogRef.close(this.funder.value);
    this.snackbar.open('Saved Funder successfully', 'Close', { duration: 5000 });
  }

  ngOnInit(): void {
    this.funder = this.data.funder;
  }

}
