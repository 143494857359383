import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {nodeMetaDataJsonSchema7, SFNodeType} from '@sciflow/schema';
import {EditorService} from 'editor';
import {NodeMetaEditorDialog} from '../../../text-elements/node-meta-editor-dialog/node-meta-editor-dialog.component';
import {deleteNodeById, updateNodeAttrs} from '../../commands';

@Component({
  selector: 'sfo-quick-actions-menu',
  templateUrl: './quick-actions-menu.component.html',
  styleUrls: ['./quick-actions.menu.component.scss'],
  standalone: false,
})
export class QuickActionsMenuComponent {
  private view;

  node;

  constructor(
    private dialog: MatDialog,
    private editorService: EditorService,
  ) {
    this.editorService.currentView.subscribe((view) => {
      this.view = view;
      this.node = view?.state?.selection?.['node'];
    });
  }

  deleteNode() {
    deleteNodeById(this.node?.attrs?.id)(this.view.state, this.view.dispatch);
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(NodeMetaEditorDialog, {
      data: {
        node: this.node,
        schema: nodeMetaDataJsonSchema7[SFNodeType.figure],
        sfNodeType: SFNodeType.figure,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'cancelled') {
        return;
      }

      // FIXME #1125 : Refactor ui library form return logic
      const extraProcessing = {
        alt: !result?.data?.alt ? null : result?.data?.alt,
        decorative: !result?.data?.decorative ? false : result?.data?.decorative,
      };

      updateNodeAttrs(this.node?.attrs?.id, extraProcessing)(this.view.state, this.view.dispatch);
    });
  }
}
