import { inject, NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../editor/error-handler/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('../editor/editor.module').then(m => m.EditorModule)  },
  { path: 'write', redirectTo: '/', pathMatch: 'full' },
  { path: '404', component: PageNotFoundComponent },
  { path: '500', component: PageNotFoundComponent },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      errorHandler: (error: any) => {
        const router = inject(Router);
        console.error('Fatal error', error);
        router.navigate(['/500']);
      },
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
