import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FigurePreviewModalComponent} from '../figure-preview-modal/figure-preview-modal.component';
import {NodeMetaEditorDialog} from '../../node-meta-editor-dialog/node-meta-editor-dialog.component';
import {nodeMetaDataJsonSchema7, SFNodeType} from '@sciflow/schema';

@Component({
    selector: 'sf-figure-menu',
    templateUrl: './figure-menu.component.html',
    styleUrls: ['./figure-menu.component.scss'],
    standalone: false
})
export class FigureMenuComponent implements OnInit {
  figureNode: any = {attrs: {}};
  @Input() set node(value) {
    this.figureNode = value;
  }

  @Input('document-id') documentId;
  @Input() isUploading = false;
  @Input() isDropTarget = false;
  @Input() captionData;
  @Input() file;

  @ViewChild('fileUpload', {static: true}) fileUploadButton: ElementRef;

  @Output() commands = new EventEmitter();

  emit(command: {id: string; payload?: any}) {
    this.commands.emit(command);
  }

  constructor(public dialog: MatDialog) {}

  delete() {
    this.emit({id: 'delete'});
  }

  addCaption() {
    this.emit({id: 'add-caption'});
  }

  uploadImage() {
    this.fileUploadButton.nativeElement.click();
  }

  async onUpload(event) {
    const files = (event.srcElement || event.target).files;
    this.isUploading = true;
    // delete this.figureNode.attrs.src;

    this.emit({id: 'upload-image', payload: {files}});
  }

  getBase64(file): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  isEmptyImageFigure(node) {
    return node.attrs.type === 'figure' && (!node.attrs.src || node.attrs.src.length === 0);
  }

  zoom(file?: {id: string; url: string}) {
    this.dialog
      .open(FigurePreviewModalComponent, {
        data: {
          documentId: this.documentId,
          figureId: this.figureNode.attrs.id,
          thumbnail: this.figureNode.attrs.src,
          node: this.figureNode,
          file,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.emit({id: 'set-selection'});
      });
  }

  isEmptyNativeTable(node) {
    return (
      node.attrs.type === 'table' &&
      !node.attrs.src &&
      node.content.content.find((node) => node.type.name === 'table') == null
    );
  }

  isImageFigure(node) {
    if (!this.isEmpty(node) && !this.isActiveNativeTable(node)) {
      return this.figureNode.attrs.type;
    }
  }

  isEmpty(node): boolean {
    // we neither have a (native table nor a table image)
    return !(this.isActiveNativeTable(node) || node.attrs.src);
  }

  isActiveNativeTable(node) {
    return (
      node.attrs.type === 'table' &&
      node.content.content.find((node) => node.type.name === 'table') != null
    );
  }

  orientate(orientation: 'landscape' | 'portrait') {
    this.emit({id: 'rotate'});
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(NodeMetaEditorDialog, {
      data: {
        node: this.figureNode,
        schema: nodeMetaDataJsonSchema7[SFNodeType.figure],
        sfNodeType: SFNodeType.figure,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'cancelled') {
        return;
      }
      // FIXME #1125 : Refactor ui library form return logic
      const extraProcessing = {
        alt: !result?.data?.alt ? null : result?.data?.alt,
        decorative: !result?.data?.decorative ? false : result?.data?.decorative,
      };

      this.emit({
        id: 'update-alt-text',
        payload: {id: this.figureNode?.attrs?.id, result: extraProcessing},
      });
    });
  }

  ngOnInit() {}
}
