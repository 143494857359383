import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SfoUiJSONSchema7} from '../../metadata.model';
import {ViewType} from '../view.types';
import {CommonModule} from '@angular/common';
import {DynamicFieldModule} from '../form-fields';

@Component({
    selector: 'sfo-simple-settings-view',
    templateUrl: './simple-settings-view.component.html',
    imports: [CommonModule, DynamicFieldModule]
})
export class SimpleSettingsComponent {
  @Input() schema: SfoUiJSONSchema7;
  @Input() aFormControl: FormGroup;
  @Input() settingsView: ViewType | undefined;

  get controlsInOrder(): string[] {
    return Object.keys(this.aFormControl.controls);
  }

  getFormControl(control: string): FormGroup {
    return this.aFormControl.get(control) as FormGroup;
  }
}
