import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
// import { WindowRef } from '../../../core';

@Component({
    selector: 'sf-hyperlink-dialog',
    templateUrl: './hyperlink-dialog.component.html',
    styleUrls: ['./hyperlink-dialog.component.scss'],
    standalone: false
})
export class HyperlinkDialogComponent implements OnInit {

  @ViewChild('urlField', { static: true }) urlField: ElementRef<MatInput>;
  @ViewChild('textField', { static: true }) textField: ElementRef<MatInput>;
  @ViewChild('titleField', { static: true }) titleField: ElementRef<MatInput>;

  isMac = navigator.userAgent.indexOf('Mac OS X') !== -1;
  addLinkFormGroup = new UntypedFormGroup({
    linUrl : new UntypedFormControl('', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
  });

  constructor(public dialogRef: MatDialogRef<HyperlinkDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data?.text) {
      this.urlField.nativeElement.focus();
    }

    this.addLinkFormGroup.patchValue({
      linUrl: this.data?.attrs?.href
    });
  }

  inputKeyDown(event) {
    if (event.code === 'Enter' && this.urlField.nativeElement.value.length > 0) {
      event.preventDefault();
      this.submit();
    }
  }

  delete() {
    this.close({
      id: this.data?.attrs && this.data?.attrs.id,
      delete: true
    });
  }

  submit() {

    const text = this.textField.nativeElement.value &&
      this.textField.nativeElement.value.length > 0
      ? this.textField.nativeElement.value
      : this.urlField.nativeElement.value;

    this.close({
      id: this.data?.attrs && this.data?.attrs.id,
      isNew: !this.data?.attrs || !(this.data?.attrs && this.data?.attrs.id),
      text,
      attrs: {
        ...this.data?.attrs,
        href: this.urlField.nativeElement.value,
        title: this.titleField.nativeElement.value
      }
    });
  }

  close(data?) {
    // giving back focus to the view before removing the window will make selection possible
    if (typeof this.data?.focus === 'function') { this.data?.focus(); }
    this.dialogRef.close(data || { id: this.data?.attrs && this.data?.attrs.id });
  }

  openUrl() {
    if (this.urlField.nativeElement.value.length > 0) {
    //   this.windowRef.open(this.urlField.nativeElement.value, '_blank');
    }
  }

}
