import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {exampleSetup} from 'prosemirror-example-setup';
import {keymap} from 'prosemirror-keymap';
import {DOMParser, DOMSerializer, Schema} from 'prosemirror-model';
import {schema} from 'prosemirror-schema-basic';
import {addListNodes} from 'prosemirror-schema-list';
import {EditorState, Plugin, PluginKey} from 'prosemirror-state';
import {EditorView} from 'prosemirror-view';
import {Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

const mySchema = new Schema({
  nodes: addListNodes(schema.spec.nodes, 'paragraph block*', 'block'),
  marks: schema.spec.marks,
});

@Component({
    selector: 'sfo-rich-text-input',
    templateUrl: './rich-text.component.html',
    styleUrls: ['./rich-text.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: SfoRichTextInputComponent }],
    standalone: false
})
export class SfoRichTextInputComponent
  implements AfterViewInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor
{
  static nextId = 0;
  @HostBinding() id = `sf-rich-text-input-${SfoRichTextInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  stateChanges = new Subject<void>();

  @Input('aria-describedby') userAriaDescribedBy: string;
  @Output('enter') emitEnter = new EventEmitter();
  @Output() focusEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();

  /** The ProseMirror editor host element */
  @ViewChild('editor') editor: ElementRef<HTMLDivElement>;

  @Input()
  get value(): string {
    return this.text;
  }

  set value(text: string) {
    if (!text) {
      text = '';
    }
    if (text === this.text) {
      return;
    }

    this.text = text;
    this.initEditor(text);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // TODO this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  focused = false;

  focus() {
    if (!this.focused) {
      this.focused = true;

      this.view?.focus();
      this.focusEvent.emit();

      this.stateChanges.next();
    }
  }

  blur() {
    if (this.focused) {
      this.focused = false;
      this.blurEvent.emit();
      this.stateChanges.next();
    }
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.sf-rich-text-input-container',
    )!;
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    this.editor.nativeElement.click();
  }

  controlType = 'sf-rich-text-input';

  get empty() {
    return !this.text || this.textLength === 0;
  }

  touched = false;
  /** The length of the text with no respect to html elements */
  private textLength = 0;

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      //this.onTouched();
      this.stateChanges.next();
    }
  }

  get errorState(): boolean {
    return !this.valid && this.touched;
  }

  text: string = '';
  valid = true;
  private view;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Self() public ngControl: NgControl,
    public _elementRef: ElementRef,
    @Optional() @Inject(MAT_FORM_FIELD) private _formField?: MatFormField,
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.initEditor(this.text);
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(map((_) => this.text)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initEditor(html: string) {
    if (!this.editor?.nativeElement) {
      return;
    }

    const doc = this.document.createElement('div');
    doc.innerHTML = html;

    const stylePlugin = new Plugin({
      key: new PluginKey('style'),
      props: {
        attributes: {
          class: 'ProseMirror-SciFlow-RichText-style',
          spellcheck: 'false',
          'data-gramm': 'false',
        },
      },
    });

    const placeholderPlugin = new Plugin({
      key: new PluginKey('placeholder'),
      props: {
        attributes: (state) => {
          if (state.doc.textContent?.length === 0 && this.placeholder?.length > 0) {
            return {
              'data-placeholder': this.placeholder,
              'data-has-label': this._formField?._hasFloatingLabel(),
            };
          }
          return {};
        },
      } as any,
    });

    if (this.view) {
      this.view.destroy();
      this.view = null;
    }

    this.view = new EditorView(this.editor.nativeElement, {
      state: EditorState.create({
        doc: DOMParser.fromSchema(mySchema).parse(doc),
        plugins: [
          keymap({
            Enter: () => {
              this.emitEnter?.emit();
              return true;
            },
          }),
          ...exampleSetup({schema: mySchema, menuBar: false}),
          stylePlugin,
          placeholderPlugin,
        ],
      }),
      handleDOMEvents: {
        'blur': (view, event) => {
          this.blur();
          return false;
        },
        'focus': (view, event) => {
          this.focus();
          return false;
        },
      },
      dispatchTransaction: (transaction) => {
        const result = this.view.state.applyTransaction(transaction);
        const newState = result.state;

        if (this.disabled && transaction.docChanged) {
          return;
        }

        this.view.updateState(newState);
        if (transaction.docChanged) {
          const docHTML: Node = DOMSerializer.fromSchema(mySchema).serializeFragment(
            newState.doc.content,
          );

          const output = this.document.createElement('div');
          output.append(docHTML);
          this.text = output.innerHTML;
          this.textLength = newState.doc.textContent.length;
          this.stateChanges.next();
        }
      },
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    if (this.view) {
      this.view.destroy();
    }
  }
}
