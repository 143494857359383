import {Pipe, PipeTransform} from '@angular/core';
import {SfoUiJSONSchema7} from '../../metadata.model';

/**
 * Pipe to transform an object into an array of key-value pairs without sorting.
 * This pipe is pure, meaning it only re-evaluates when the input reference changes.
 */
@Pipe({
    name: 'unsortedKeyvalue',
    pure: true,
    standalone: false
})
export class UnsortedKeyvaluePipe implements PipeTransform {
  /**
   * Transforms an object into an array of key-value pairs without ordering.
   *
   * @param { { [key: string]: SfoUiJSONSchema7 } } value - The input object to transform.
   * @returns { Array<{ key: string, value: SfoUiJSONSchema7 }> } - An array of key-value pairs.
   */
  transform(value: {
    [key: string]: SfoUiJSONSchema7;
  }): Array<{key: string; value: SfoUiJSONSchema7}> {
    if (!value) {
      return [];
    }

    return Object.keys(value).map((key) => ({key, value: value[key]}));
  }
}
