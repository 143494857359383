import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';

import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import {SfoWidgetModule} from '../widgets';
import {DynamicFormComponent} from './dynamic-form.component';
import {DynamicFieldModule} from './form-fields';
import {SfoFormHelperService} from './form-helper.class';
import {QuickSettingsModule} from './quicksettings';
import {SettingsViewTabModule} from './settings-view-tab';
import {SimpleSettingsComponent} from './simple-settings-view/simple-settings-view.component';

@NgModule({
  declarations: [DynamicFormComponent],
  imports: [
    CommonModule,
    DynamicFieldModule,
    ReactiveFormsModule,
    SettingsViewTabModule,
    QuickSettingsModule,
    SimpleSettingsComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule,
    SfoWidgetModule,
  ],
  exports: [DynamicFormComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'},
    },
    SfoFormHelperService,
  ],
})
export class SfoDynamicFormModule {}
