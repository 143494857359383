<div *ngFor="let control of controlsInOrder">
  <h2 class="h4 m0">
    {{ schema.properties?.[control]?.title ?? control | titlecase }}
  </h2>
  <sfo-dynamic-field
    [schema]="schema.properties?.[control]"
    [aFormControl]="aFormControl"
    [settingsView]="'quickSettings'"
    [control]="control"
  ></sfo-dynamic-field>
</div>
