<div class="flex flex-column mat-elevation-z1 menu">
  <button
    mat-icon-button
    [matTooltip]="node?.attrs?.decorative ? 'Edit Alt Text (image is decorative)' : node?.attrs?.alt ? 'Edit Alt Text' : 'Add Alt Text (currently missing)'"
    matTooltipPosition="right"
    (click)="openEditDialog()"
  >
    <mat-icon [matBadge]="!node?.attrs?.alt && !node?.attrs?.decorative ? '1' : undefined"
      >add_photo_alternate</mat-icon
    >
  </button>
  <button mat-icon-button matTooltip="Delete" matTooltipPosition="right" (click)="deleteNode()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
