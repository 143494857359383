import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject, BehaviorSubject } from 'rxjs';
import { EditorView } from '@codemirror/next/view';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MathService } from '../math.service';
import { debounceTime } from 'rxjs/operators';
import { EditorState, basicSetup } from '@codemirror/next/basic-setup';

@Component({
    selector: 'sfo-math-editor',
    templateUrl: './math-editor.component.html',
    styleUrls: ['./math-editor.component.scss'],
    standalone: false
})
export class MathEditorComponent {
  @ViewChild('displayToggle') displayToggle: MatSlideToggle;
  @ViewChild('preview', { static: true }) preview: ElementRef;
  @ViewChild('editor') editorRef?: ElementRef;

  results$ = new Subject<{ errors: any[], node: SafeHtml | null }>();
  useMathJax$ = new BehaviorSubject(localStorage.getItem('use-math') !== 'true');

  @Input() set numbered(val: boolean) {
    this.isInline$.next(!val);
  }
  @Input() toggleHelp = true;
  @Input() set tex(tex: string) {
    this.texInput$.next(tex);
  }

  constructor(public dialogRef: MatDialogRef<MathEditorComponent>, private sanitizer: DomSanitizer, private mathService: MathService) { }
  /*** Input from outside (e.g. the editor node) */
  texInput$ = new BehaviorSubject<string>('');
  view?: EditorView;

  commandInput$ = new Subject<string>();
  previewValidation$ = new Subject<string>();
  isInline$ = new BehaviorSubject<boolean>(true);

  ngAfterViewInit() {
    this.texInput$
      .pipe(debounceTime(200))
      .subscribe((tex) => {
        try {
          this.preview.nativeElement.innerHTML = this.mathService.renderMathJax(tex);
          this.previewValidation$.next('');
        } catch (e: any) {
          this.previewValidation$.next(e.message.replace('MathJax parse error: ', ''));
        }
      });

    this.view = new EditorView({
      state: EditorState.create({
        doc: this.texInput$.value,
        extensions: [basicSetup, EditorView.updateListener.of(({ state }) => {
          const content = state.doc.toString();
          this.texInput$.next(content);
          this.render(content);
        })]
      }),
      parent: this.editorRef?.nativeElement
    });

    setTimeout(() => {
      this.setFocus();
    }, 250);
  }

  setFocus(): void {
    this.view?.focus();
  }

  replace(text: string): void {
    const from = 0;
    const to = this.view?.state.doc.length;
    this.view?.dispatch({ changes: { from, to, insert: text } });
  }

  insert(text: string): void {
    let from = 0;
    let to = 0;
    if (this.view && this.view?.state.selection.ranges.length > 0) {
      to = this.view?.state?.selection.ranges[0].to || 0;
      from = this.view?.state?.selection.ranges[0].from || 0;
    }
    this.view?.dispatch({ changes: { from, to, insert: text } });
  }

  toggleLegacy(event: MatSlideToggleChange): void {
    this.useMathJax$.next(event.checked);
    localStorage.setItem('use-PathJax-math', (!event.checked).toString());
    this.render(this.texInput$.value);
  }

  async render(content: string): Promise<void> {
    try {
      const result = await this.mathService.renderEquation(content);
      this.results$.next({ errors: result?.errors, node: result?.node ? this.sanitizer.bypassSecurityTrustHtml(result.node) : null });
    } catch (e: any) {
      this.previewValidation$.next('');
      this.results$.next({ errors: [{ id: 'Error', message: e.message.replace('MathJax parse error: ', '') }], node: null });
    }
  }

  acceptInlineToggle({ checked }): void {
    this.isInline$.next(checked);
  }

  acceptTexInput(tex: string): void {
    this.texInput$.next(tex);
  }

  apply(): void {
    this.dialogRef.close({
      numbered: !this.isInline$.value,
      tex: this.texInput$.value
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
